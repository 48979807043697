const initialState = {
  loader: false,
  mobilemenu: false,
};

export const KrishnaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "loader_start":
      return { ...state, loader: true };
    case "loader_stop":
      return { ...state, loader: false };
    case "mobilemenu_open":
      return { ...state, mobilemenu: true };
    case "mobilemenu_close":
      return { ...state, mobilemenu: false };
    default:
      return { ...state };
  }
};
