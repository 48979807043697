import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
const Menu = () => {
  const location = useLocation();
  const mobileMenu = useSelector((state) => state.krishna.mobilemenu);
  const dispatch = useDispatch();

  const loadMobileMenu = () => {
    dispatch({ type: "mobilemenu_open" });
  };

  const closeMobileMenu = () => {
    dispatch({ type: "mobilemenu_close" });
  };

  useEffect(closeMobileMenu, [dispatch]);

  return (
    <header className="header clearfix">
      <a
        href={(e) => e.preventDefault()}
        className={mobileMenu ? "menu_btn active" : "menu_btn"}
        onClick={loadMobileMenu}
        style={{ color: "#fff" }}
      >
        <i className="icofont-navigation-menu"></i>Menu
      </a>
      <nav className="mainMenu" style={{ display: mobileMenu ? "block" : "" }}>
        <ul className="clearfix">
          <li className={location.pathname === "/" ? "active" : ""}>
            <Link to="/">
              <i className="icon icon-House"></i>
              <span>Home</span>
            </Link>
          </li>
          <li className={location.pathname === "/resume" ? "active" : ""}>
            <Link to="/resume">
              <i className="icon icon-User"></i>
              <span>Resume</span>
            </Link>
          </li>
          {/* <li className={location.pathname === "/portfolio" ? "active" : ""}>
            <Link to="/portfolio">
              <i className="icon icon-Bulb"></i>
              <span>Portfolio</span>
            </Link>
          </li>
          <li className={location.pathname === "/blog" ? "active" : ""}>
            <Link to="/blog">
              <i className="icon icon-ClipboardText"></i>
              <span>Blog</span>
            </Link>
          </li> */}
          <li className={location.pathname === "/contact" ? "active" : ""}>
            <Link to="/contact">
              <i className="icon icon-Imbox"></i>
              <span>Contact</span>
            </Link>
          </li>
        </ul>
      </nav>

      <a href={(e) => e.preventDefault()} className="sidebarToggler">
        <img
          src="assets/images/home_01/nkrishna-logo.jpg"
          className="img-fluid"
          alt="Nkrishnalogo"
        />
      </a>
    </header>
  );
};

export default Menu;
