import React from "react";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div className="profileSidebar">
      <div className="psHeader">
        <svg preserveAspectRatio="none" viewBox="0 0 100 100">
          <polygon points="0 20, 100 20, 0 100" opacity=".65"></polygon>
        </svg>
        <svg className="svg2" preserveAspectRatio="none" viewBox="0 0 100 120">
          <polygon points="0 20, 100 20, 15 120" opacity=".8"></polygon>
        </svg>
        <div className="psContent">
          <h3>N.Krishna Chaithanya</h3>
          <span>Sr. Developer</span>
        </div>
      </div>
      <div className="psPhoto">
        <img src="assets/images/home_01/1.jpg" alt="" />
        <div className="psSocial">
          <a
            href="https://www.facebook.com/kchaitu27/"
            target="_blank"
            rel="noreferrer"
            className="fac"
          >
            <i className="icofont-facebook"></i>
          </a>
          <a
            href="https://twitter.com/kchaitu27"
            target="_blank"
            rel="noreferrer"
            className="twi"
          >
            <i className="icofont-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/kchaitu27/"
            target="_blank"
            rel="noreferrer"
            className="dri"
          >
            <i className="icofont-linkedin"></i>
          </a>
          <a
            href="https://www.instagram.com/kchaitu27/"
            target="_blank"
            rel="noreferrer"
            className="ins"
          >
            <i className="icofont-instagram"></i>
          </a>
        </div>
      </div>
      <div className="psSkills">
        <div className="pssBox" data-count="85">
          <div className="pssbCount">70.</div>
          <h5>Backend Development</h5>
          <div className="pssbBars">
            <div className="pssbBar" style={{ width: "0%" }}></div>
          </div>
        </div>
        <div className="pssBox" data-count="92">
          <div className="pssbCount">85.</div>
          <h5>Frontend Development</h5>
          <div className="pssbBars">
            <div className="pssbBar gr2" style={{ width: "0%" }}></div>
          </div>
        </div>
      </div>
      <div className="psFooter">
        <a
          href={(e) => e.preventDefault()}
          className="btt_btn only_icon btt_reverse"
        >
          <span>
            <i className="icon icon-DownloadCloud"></i>
          </span>
        </a>
        <Link to="/contact" className="btt_btn">
          <span>
            <i className="icon icon-Bag"></i> Hire Me
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Header;
