import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <Fragment>
      <div className="sidebar mCustomScrollbar _mCS_1">
        <a href={(e) => e.preventDefault()} className="widget_closer">
          <i className="icofont-close-line"></i>
        </a>
        <div className="widget widget-search">
          <h3 className="widget_title">Search</h3>
          <form className="search_form">
            <input type="text" name="s" placeholder="Enter Keword" />
            <button type="submit">
              <i className="icon icon-Search"></i>
            </button>
          </form>
        </div>
        <div className="widget widget-search">
          <h3 className="widget_title">Categories</h3>
          <ul>
            <li>
              <Link to="/blog">Web</Link>(18)
            </li>
            <li>
              <Link to="/blog">Startup</Link>(11)
            </li>
            <li>
              <Link to="/blog">Branding</Link>(17)
            </li>
            <li>
              <Link to="/blog">UI UX</Link>(23)
            </li>
            <li>
              <Link to="/blog">Agency</Link>(19)
            </li>
          </ul>
        </div>
        <div className="widget">
          <h3 className="widget_title">Recent Posts</h3>
          <div className="singleRecent">
            <img src="assets/images/blog/7.jpg" alt="" />
            <span>01 Apr, 2021</span>
            <Link to="/">Create your next web site with Unity.</Link>
          </div>
          <div className="singleRecent">
            <img src="assets/images/blog/8.jpg" alt="" />
            <span>31 Mar, 2021</span>
            <Link to="/">Truly unique hundreds of elements.</Link>
          </div>
          <div className="singleRecent">
            <img src="assets/images/blog/9.jpg" alt="" />
            <span>26 Mar, 2021</span>
            <Link to="/">Easily create unlimited amount of custom.</Link>
          </div>
        </div>
        <div className="widget">
          <h3 className="widget_title">Tags</h3>
          <div className="tabclouds">
            <Link to="/blog">Web Design</Link>
            <Link to="/blog">Development</Link>
            <Link to="/blog">CSS</Link>
            <Link to="/blog">Studio</Link>
            <Link to="/blog">Award</Link>
            <Link to="/blog">Mobile</Link>
            <Link to="/blog">IOS</Link>
            <Link to="/blog">IOS</Link>
            <Link to="/blog">UI / UX</Link>
            <Link to="/blog">Portfolio</Link>
          </div>
        </div>
      </div>
      <div className="sidebarOverlay"></div>
    </Fragment>
  );
};

export default Sidebar;
