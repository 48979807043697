import React, { Fragment, useEffect } from "react";
import Menu from "./../common/Menu";
import Sidebar from "./../common/Sidebar";
import Header from "./../common/Header";
import Footer from "./../common/Footer";
import Loader from "./../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Home = (props) => {
  const loaderStatus = useSelector((state) => state.krishna.loader);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "loader_start" });
    setTimeout(() => {
      dispatch({ type: "loader_stop" });
    }, 3000);
  }, []);

  return (
    <Fragment>
      <Helmet></Helmet>
      {loaderStatus ? <Loader /> : ""}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 profileColumn">
            <Header />
          </div>
          <div className="col-lg-8 contentColumn">
            <Menu />
            <Sidebar />
            <div className="bodyContent">
              <div className="pageCointainer">
                <section className="comonSection aboutSection">
                  <div className="container-fluid">{props.children}</div>
                </section>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href={(e) => e.preventDefault()} id="backtotop">
        <i className="icofont-bubble-up"></i>
      </a>
      <Helmet></Helmet>
    </Fragment>
  );
};

export default Home;
