import React, { useState } from "react";
import Hoc from "./hoc";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [userInfo, setUserInfo] = useState({});

  const sendEmail = async () => {
    const formData = JSON.stringify(userInfo);
    const response = await axios
      .post("https://www.nkrishna.in/server/api/email", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res)
      .catch((error) => error);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    sendEmail();
    toast("Thank you very much ! Form Submitted Successfully !");
    setUserInfo({
      fullname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  };

  const formHandler = (e) => {
    let { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  return (
    <Hoc>
      <Helmet>
        <title>
          Contact - Full Stack Senior Developer - N Kishna Chaithanya
        </title>
        <link rel="canonical" href="https://www.nkrishna.in/#/resume" />
        <meta
          name="title"
          description="Full Stack Senior Developer - N Kishna Chaithanya"
        />
        <meta
          name="description"
          description="Full stack developer with more than 7 years experience on web development. Mainly works with React and builds web apps. Excellent knowledge in Frontend Library or framework such as jQuery, Bootstrap and Reactjs and Redux is an library for managing application state and React Hooks."
        />
        <meta
          name="keywords"
          content="Full Stack Developer, React Developer, React Redux, Senior Fullstack Developer"
        />
      </Helmet>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-12">
          <h2 className="sectionTitle">Contact Me</h2>
        </div>
      </div>
      <div className="row mb30">
        <div className="col-lg-8">
          <div className="contact_form">
            <form onSubmit={submitHandler} id="contactForm">
              <div className="row">
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="required"
                    name="fullname"
                    placeholder="Full Name *"
                    required="required"
                    value={userInfo.fullname}
                    onChange={formHandler}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="email"
                    className="required"
                    name="email"
                    value={userInfo.email}
                    placeholder="Email *"
                    required="required"
                    onChange={formHandler}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={userInfo.phone}
                    onChange={formHandler}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="required"
                    name="subject"
                    value={userInfo.subject}
                    placeholder="Subject *"
                    required="required"
                    onChange={formHandler}
                  />
                </div>
                <div className="col-lg-12">
                  <textarea
                    name="message"
                    className="required"
                    placeholder="Message *"
                    required="required"
                    onChange={formHandler}
                    value={userInfo.message}
                  ></textarea>
                </div>
                <div className="col-lg-12">
                  <button type="submit" className="btt_btn">
                    <span>
                      <i className="icon icon-Mail"></i>Send Message
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="icon_box_01">
            <i className="icon icon-Mail"></i>
            <h3>Email Address</h3>
            <p>info@nkrishna.in</p>
          </div>
          <div className="icon_box_01">
            <i className="icon icon-Phone"></i>
            <h3>Phone Number</h3>
            <p>+91 8121408635</p>
          </div>
          <div className="icon_box_01 addrBox">
            <i className="icon icon-Pointer"></i>
            <h3>Address</h3>
            <p>Vijayawada, Andhra Pradesh, India</p>
          </div>
        </div>
      </div>
    </Hoc>
  );
};

export default Contact;
