import React from "react";
import Hoc from "./hoc";
import { Helmet } from "react-helmet";

const Resume = () => {
  return (
    <Hoc>
      <Helmet>
        <title>
          Resume - Full Stack Senior Developer - N Kishna Chaithanya
        </title>
        <link rel="canonical" href="https://www.nkrishna.in/#/resume" />
        <meta
          name="title"
          description="Full Stack Senior Developer - N Kishna Chaithanya"
        />
        <meta
          name="description"
          description="Full stack developer with more than 7 years experience on web development. Mainly works with React and builds web apps. Excellent knowledge in Frontend Library or framework such as jQuery, Bootstrap and Reactjs and Redux is an library for managing application state and React Hooks."
        />
        <meta
          name="keywords"
          content="Full Stack Developer, React Developer, React Redux, Senior Fullstack Developer"
        />
      </Helmet>
      <div className="row">
        <div className="col-lg-6">
          <h2 className="sectionTitle">Education</h2>
          <div className="kr-accordion" id="edication_accordion">
            <div className="card">
              <div className="card-header" id="ea_03">
                <h2 className="mb-0">
                  <a
                    className="collapsed"
                    href={(e) => e.preventDefault()}
                    data-toggle="collapse"
                    data-target="#ea_col_03"
                    aria-expanded="false"
                    aria-controls="ea_col_03"
                  >
                    <i className="icon icon-Medal"></i>
                    <span>Electronics &amp; Communication Engeneering</span>
                  </a>
                </h2>
              </div>
              <div
                id="ea_col_03"
                className="collapse"
                aria-labelledby="ea_01"
                data-parent="#edication_accordion"
              >
                <div className="card-body">
                  <div className="ac_meta">
                    <span>Passing Year: 2011</span>
                    <span>Grade: 64% </span>
                  </div>
                  B.E for GGR College of Engineering, Vellore, Affiliated to
                  Anna University
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="ea_02">
                <h2 className="mb-0">
                  <a
                    className="collapsed"
                    href={(e) => e.preventDefault()}
                    data-toggle="collapse"
                    data-target="#ea_col_02"
                    aria-expanded="false"
                    aria-controls="ea_col_02"
                  >
                    <i className="icon icon-Medal"></i>
                    <span>Board of Intermediate Education</span>
                  </a>
                </h2>
              </div>
              <div
                id="ea_col_02"
                className="collapse"
                aria-labelledby="ea_02"
                data-parent="#edication_accordion"
              >
                <div className="card-body">
                  <div className="ac_meta">
                    <span>Passing Year: 2007</span>
                    <span>Grade: 79.9%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="ea_01">
                <h2 className="mb-0">
                  <a
                    className=""
                    href={(e) => e.preventDefault()}
                    data-toggle="collapse"
                    data-target="#ea_col_01"
                    aria-expanded="true"
                    aria-controls="ea_col_01"
                  >
                    <i className="icon icon-Medal"></i>
                    <span>Board of Secondary Education</span>
                  </a>
                </h2>
              </div>
              <div
                id="ea_col_01"
                className="collapse show"
                aria-labelledby="ea_01"
                data-parent="#edication_accordion"
              >
                <div className="card-body">
                  <div className="ac_meta">
                    <span>Passing Year: 2005</span>
                    <span>Grade: 69%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <h2 className="sectionTitle">Experience</h2>
          <div className="kr-accordion" id="exps_accordion">
            <div className="card">
              <div className="card-header" id="ex_01">
                <h2 className="mb-0">
                  <a
                    className=""
                    href={(e) => e.preventDefault()}
                    data-toggle="collapse"
                    data-target="#ex_col_01"
                    aria-expanded="true"
                    aria-controls="ex_col_01"
                  >
                    <i className="icon icon-Cup"></i>
                    <span>Virtu Information Technologies Pvt Ltd.</span>
                  </a>
                </h2>
              </div>
              <div
                id="ex_col_01"
                className="collapse show"
                aria-labelledby="ex_01"
                data-parent="#exps_accordion"
              >
                <div className="card-body">
                  <div className="ac_meta">
                    <span>Jan 2018 - Present</span>
                    <span>Sr. Developer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="ex_02">
                <h2 className="mb-0">
                  <a
                    className="collapsed"
                    href={(e) => e.preventDefault()}
                    data-toggle="collapse"
                    data-target="#ex_col_02"
                    aria-expanded="false"
                    aria-controls="ex_col_02"
                  >
                    <i className="icon icon-Cup"></i>
                    <span>Virtu Tech Solutions Pvt Ltd.</span>
                  </a>
                </h2>
              </div>
              <div
                id="ex_col_02"
                className="collapse"
                aria-labelledby="ex_02"
                data-parent="#exps_accordion"
              >
                <div className="card-body">
                  <div className="ac_meta">
                    <span>Nov 2014 - Dec 2017</span>
                    <span>Jr. Designer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Hoc>
  );
};

export default Resume;
