import React from "react";
import Hoc from "./hoc";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <Hoc>
      <Helmet>
        <title>
          Homepage - Full Stack Senior Developer - N Kishna Chaithanya
        </title>
        <link rel="canonical" href="https://www.nkrishna.in/#/" />
        <meta
          name="title"
          description="Homepage - Full Stack Senior Developer - N Kishna Chaithanya"
        />
        <meta
          name="description"
          description="Full stack developer with more than 7 years experience on web development. Mainly works with React and builds web apps. Excellent knowledge in Frontend Library or framework such as jQuery, Bootstrap and Reactjs and Redux is an library for managing application state and React Hooks."
        />
        <meta
          name="keywords"
          content="Full Stack Developer, React Developer, React Redux, Senior Fullstack Developer"
        />
      </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <h2 className="sectionTitle">About Info</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="icon_box_01">
                <i className="icon icon-User"></i>
                <h3>My Name</h3>
                <p>N.Krishna Chaithaya</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="icon_box_01">
                <i className="icon icon-Calculator"></i>
                <h3>My Age</h3>
                <p>31 Years 60 Days</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="icon_box_01">
                <i className="icon icon-Mail"></i>
                <h3>Email Address</h3>
                <p>info@nkrishna.in</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="icon_box_01">
                <i className="icon icon-Phone"></i>
                <h3>Phone Number</h3>
                <p>+91 8121408635</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="icon_box_01">
                <i className="icon icon-Pointer"></i>
                <h3>Address</h3>
                <p>Vijayawada, Andhra Pradesh</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt30">
        <div className="col-lg-12">
          <h2 className="sectionTitle mb22">Short Bio</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="abContent">
            <p>
              Full stack developer with more than 7 years experience on web
              development. Mainly works with React and builds web apps.
              Excellent knowledge in Frontend Library or framework such as
              jQuery, Bootstrap and Reactjs and Redux is an library for managing
              application state and React Hooks.
            </p>
            <p>
              Proficient in all the versions of bootstrap versions 2x , 3x, 4x.
              antd and semantic ui and Goodknowledge in conversion of PSD to
              HTML designs. PSD to React and wireframe to React Conversion.
            </p>
          </div>
        </div>
      </div>
    </Hoc>
  );
};

export default Home;
