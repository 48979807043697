import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Blog from "./components/Blog";
import Portfolio from "./components/Portfolio";
import Resume from "./components/Resume";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/contact" component={Contact} />
        <Route path="/blog" component={Blog} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/resume" component={Resume} />
        <Route path="/" exact component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
